"use client";

import { ReactElement } from "react";
import { getContentfulVideoUrl } from "../../helpers/getContentfulVideoUrl";
import { CosmosButton, CosmosIconFeedback } from "@cosmos/web/react";
import { responsiveImageHelper } from "../../helpers/responsiveImageHelper";
import { Kicker } from "../kicker/kicker";
import { useImageLoading } from "../../helpers/ImageLoadingContext";
import classnames from "classnames/bind";
import styles from "./stage.module.css";
import { getImageSource } from "../../helpers/getImageSource";

interface Props {
  backgroundImageUrl?: string | null;
  name: string | null;
  title: string | null;
  description?: string | null;
  linkUrl?: string | null;
  linkText?: string | null;
  videoUrl?: string | null;
  withRbButton?: boolean;
  filter?: ReactElement;
  onClickLink?: () => void;
}

const cx = classnames.bind(styles);

export const Stage = ({
  backgroundImageUrl,
  name,
  title,
  description,
  linkUrl,
  linkText,
  videoUrl,
  withRbButton,
  filter,
  onClickLink,
}: Props) => {
  const imageLoading = useImageLoading();
  const imageSource = backgroundImageUrl
    ? getImageSource(backgroundImageUrl)
    : null;

  return (
    <div className={cx("container")}>
      <div className={cx("content")}>
        {backgroundImageUrl && !videoUrl && (
          <div className={cx("image")}>
            <div className={cx("image-view")}>
              <div className={cx("image-container")}>
                {backgroundImageUrl &&
                  (() => {
                    const imageProps = {
                      loading: imageLoading,
                      className: cx("image-content"),
                      alt: "",
                      sizes: "100vw",
                      height: undefined,
                      width: undefined,
                    };

                    switch (imageSource) {
                      case "contentful": {
                        return (
                          <img
                            {...imageProps}
                            srcSet={responsiveImageHelper.createContentfulSrcSet(
                              backgroundImageUrl,
                            )}
                          />
                        );
                      }
                      case "storyblok": {
                        return (
                          <img
                            {...imageProps}
                            srcSet={responsiveImageHelper.createStoryblokSrcSet(
                              backgroundImageUrl,
                            )}
                          />
                        );
                      }
                      default: {
                        return <img {...imageProps} src={backgroundImageUrl} />;
                      }
                    }
                  })()}
              </div>
            </div>
          </div>
        )}
        {videoUrl && (
          <div className={cx("video-wrapper")}>
            <video
              className={cx("video")}
              preload="auto"
              autoPlay={true}
              playsInline={true}
              loop={true}
              muted={true}
              src={getContentfulVideoUrl(videoUrl)}
            />
          </div>
        )}
        <div className={cx("layout-wrapper")}>
          <div className={cx("layout-wrapper-inner")}>
            {(name || title) && (
              <div className={cx("heading-group")}>
                {name && (
                  <h1 className={cx("kicker-wrapper")}>
                    <Kicker kind="bare" text={name} />
                  </h1>
                )}
                {/* If kicker exists, it should be the H1 */}
                {name ? (
                  <p className={cx("title")}>
                    {title && parseTitleForYears(title, new Date())}
                  </p>
                ) : (
                  <h1 className={cx("title")}>
                    {title && parseTitleForYears(title, new Date())}
                  </h1>
                )}
              </div>
            )}
            {description && <p className={cx("description")}>{description}</p>}
            {withRbButton ? (
              <CosmosButton
                href={linkUrl ?? undefined}
                size="large"
                kind="primary"
                appearance="light"
                iconPlacement="before"
                className={cx("cta-button")}
                onClick={onClickLink}
              >
                <CosmosIconFeedback
                  slot="icon"
                  className={cx("cta-button-icon")}
                />
                {linkText}
              </CosmosButton>
            ) : (
              linkUrl &&
              linkText && (
                <CosmosButton
                  href={linkUrl ?? undefined}
                  size="large"
                  kind="link"
                  appearance="light"
                  className={cx("link")}
                  onClick={onClickLink}
                >
                  {linkText}
                </CosmosButton>
              )
            )}
          </div>
        </div>
        {filter}
      </div>
    </div>
  );
};

export function parseTitleForYears(title: string, endDate: Date) {
  if (title.includes("{years}")) {
    const startYear = 1987;
    const currentYear = endDate.getFullYear();
    const currentYearFormatted =
      // Start date was 1987-04-01
      new Date(`${currentYear}-04-01`) <= endDate
        ? currentYear
        : currentYear - 1;
    const diff = currentYearFormatted - startYear;

    return title.replace("{years}", String(diff));
  }

  return title;
}
