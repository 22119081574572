"use client";

import classNamesBind from "classnames/bind";
import { Kicker } from "../../kicker/kicker";
import styles from "./generic-stage.module.css";
import { useKickerContext } from "../../../helpers/KickerContext";

const cx = classNamesBind.bind(styles);

export const GenericStage = ({
  kicker,
  heading,
  headingSize,
  introduction,
}: {
  kicker: string | null;
  heading: string | null;
  headingSize: string | null;
  introduction: React.ReactNode;
}) => {
  const kickerKind = useKickerContext();

  /**
   * Refactor: Added a `headingSize` for a one off page,
   * can be `null`/`default` or `small` (small is used for a speacial use case on the "speak up" page).
   */

  return (
    <div>
      <section className={cx("container", "container--header")}>
        <div className={cx("inner")}>
          {(kicker || heading) && (
            <div className={cx("heading-wrap")}>
              {kicker && (
                <Kicker
                  className={cx("kicker")}
                  kind={kickerKind}
                  text={kicker}
                />
              )}
              {heading && (
                <h1
                  className={cx("heading")}
                  data-size={headingSize ? headingSize : undefined}
                >
                  {heading}
                </h1>
              )}
            </div>
          )}
          {introduction && (
            <div className={cx("introduction")}>
              <div className={cx("text")}>{introduction}</div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};
