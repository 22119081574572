"use client";

import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import classnames from "classnames/bind";
import styles from "./flying-chocolate-stage.module.css";

const cx = classnames.bind(styles);

export const FlyingChocolateStage = ({
  title,
  heroArtworkUrl,
  heroArtworkDescription,
  description,
}: {
  title: string | null;
  heroArtworkUrl: string | null;
  heroArtworkDescription: string | null;
  description: string | React.ReactNode | null;
}) => {
  const imageLoading = useImageLoading();

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("content")}>
          <h1 className={cx("title")}>{title}</h1>
          {heroArtworkUrl && (
            <div className={cx("artwork-wrapper")}>
              <img
                loading={imageLoading}
                srcSet={responsiveImageHelper.createContentfulSrcSet(
                  heroArtworkUrl,
                )}
                sizes="(min-width: 1000px) 500px, 400px"
                className={cx("artwork")}
                alt={heroArtworkDescription ?? ""}
              />
            </div>
          )}
          <div className={cx("description")}>{description}</div>
        </div>
      </div>
    </div>
  );
};
