"use client";

import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { Kicker } from "../../kicker/kicker";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import classnames from "classnames/bind";
import styles from "./flying-chocolate-nutrition-facts.module.css";
import { useKickerContext } from "../../../helpers/KickerContext";
import { Prose } from "../../prose/prose";

const cx = classnames.bind(styles);

export const FlyingChocolateNutritionFacts = ({
  kicker,
  title,
  artworkUrl,
  artworkDescription,
  legalNotice,
  content,
}: {
  kicker: string | null;
  title: string | null;
  artworkUrl: string | null;
  artworkDescription: string | null;
  legalNotice: string | null;
  content: React.ReactNode;
}) => {
  const imageLoading = useImageLoading();

  const kickerKind = useKickerContext();

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        {(kicker || title) && (
          <div className={cx("header")}>
            {kicker && (
              <span className={cx("kicker-wrapper")}>
                <Kicker
                  className={cx("kicker")}
                  kind={kickerKind}
                  text={kicker}
                />
              </span>
            )}
            {title && <h2 className={cx("title")}>{title}</h2>}
          </div>
        )}
        <div className={cx("content")}>
          {artworkUrl && (
            <div className={cx("artwork-wrapper")}>
              <img
                loading={imageLoading}
                srcSet={responsiveImageHelper.createContentfulSrcSet(
                  artworkUrl,
                )}
                sizes="(min-width: 1000px) 400px, 300px"
                className={cx("artwork")}
                alt={artworkDescription ?? ""}
              />
            </div>
          )}
          <div className={cx("text")}>
            <Prose className={cx("description")}>{content}</Prose>
            <p className={cx("legal-notice")}>{legalNotice}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
