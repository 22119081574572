"use client";

import classNames from "classnames/bind";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { Kicker } from "../../kicker/kicker";
import { SliderButton } from "../../slider-button/slider-button";
import { useEffect, useState, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { useKickerContext } from "../../../helpers/KickerContext";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import styles from "./steps-carousel.module.css";
import { useTextDirectionContext } from "../../../helpers/TextDirectionContext";
import { EmblaOptionsType, EmblaCarouselType } from "embla-carousel";
import { useTranslationsContext } from "../../../helpers/TranslationsContext";
import template from "../../../helpers/template";

const cx = classNames.bind(styles);

interface StepsCarouselCard {
  heading: string | null;
  text: React.ReactNode | null;
  media: {
    url: string | null;
    description?: string | null;
  } | null;
}

interface StepsCarouselProps {
  kicker: string | null;
  heading: string | null;
  introduction: React.ReactNode | null;
  slidesCollectionItems: Array<StepsCarouselCard> | null;
}

export const StepsCarousel = ({
  kicker,
  heading,
  introduction,
  slidesCollectionItems,
}: StepsCarouselProps) => {
  const kickerKind = useKickerContext();
  const textDirection = useTextDirectionContext();
  const translations = useTranslationsContext();

  // Embla Options
  const EMBLA_OPTIONS: EmblaOptionsType = {
    direction: textDirection,
    loop: false,
    containScroll: "trimSnaps",
    watchDrag: true,
  };

  // Embla Carousel
  const [emblaRef, emblaApi] = useEmblaCarousel(EMBLA_OPTIONS);

  // Embla selected/scroll
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [previousIndex, setPreviousIndex] = useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  // Embla Next/Prev buttons
  const [prevBtnDisabled, setPrevBtnDisabled] = useState<boolean>(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState<boolean>(true);

  // Embla Prev button
  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  // Embla Next button
  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  // Embla onInit
  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  // Embla onSelect
  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPreviousIndex(emblaApi.previousScrollSnap());
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  // Embla useEffect
  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  const imageLoading = useImageLoading();

  return (
    <section className={cx("section")}>
      <div className={cx("header")}>
        <div className={cx("inner")}>
          {(kicker || heading) && (
            <h2 className={cx("heading-wrap")}>
              {kicker && (
                <Kicker
                  className={cx("kicker")}
                  kind={kickerKind}
                  text={kicker}
                />
              )}
              {heading && <span className={cx("heading")}>{heading}</span>}
            </h2>
          )}
          <div className={cx("introduction")}>{introduction}</div>
        </div>
      </div>
      <div className={cx("carousel-wrap")}>
        <div className={cx("inner")}>
          {slidesCollectionItems && (
            <div className={cx("container")}>
              <div className={cx("image-container")} aria-hidden={true}>
                {slidesCollectionItems.map((step, index) => (
                  <div
                    className={cx("image-slide", {
                      ["is-selected"]: selectedIndex === index,
                      ["is-previous"]: previousIndex === index,
                    })}
                    role="group"
                    key={index}
                  >
                    {step.media?.url && (
                      <img
                        className={cx("image-img")}
                        loading={imageLoading}
                        srcSet={responsiveImageHelper.createContentfulSrcSet(
                          step.media.url,
                        )}
                        sizes="(min-width: 1000px) min(672px, 100vw), min(600px, 100vw)"
                        alt={step.media.description ?? ""}
                      />
                    )}
                  </div>
                ))}
              </div>
              <div
                className={cx("embla")}
                dir={textDirection}
                id="steps-carousel"
              >
                <div className={cx("embla__viewport")} ref={emblaRef}>
                  <div className={cx("embla__container")}>
                    {slidesCollectionItems.map((step, index) => (
                      <div key={index} className={cx("embla__slide")}>
                        <div className={cx("card")} role="group">
                          <div className={cx("card-content")}>
                            <h3 className={cx("card-heading")}>
                              {step.heading}
                            </h3>
                            {step.text && (
                              <div className={cx("text")}>{step.text}</div>
                            )}
                          </div>
                          <div className={cx("card-image")}></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={cx("footer")}>
                  <div
                    className={cx("progress")}
                    style={{ "--c-length": scrollSnaps.length }}
                  >
                    <div
                      className={cx("progress__bar")}
                      style={{ "--c-index": selectedIndex + 1 }}
                    />
                  </div>
                  <div className={cx("slider-button-wrapper")}>
                    <SliderButton
                      disabled={prevBtnDisabled}
                      kind="previous"
                      accessibilityLabel={template(
                        translations["carousel.controls.previous"],
                        "Previous card",
                      )}
                      aria-controls="steps-carousel"
                      className={cx("slider-button", "slider-button--prev")}
                      onClick={onPrevButtonClick}
                    />
                    <SliderButton
                      disabled={nextBtnDisabled}
                      kind="next"
                      accessibilityLabel={template(
                        translations["carousel.controls.next"],
                        "Next card",
                      )}
                      aria-controls="steps-carousel"
                      className={cx("slider-button", "slider-button--next")}
                      onClick={onNextButtonClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
