"use client";

import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import classnames from "classnames/bind";
import styles from "./flying-chocolate-banner.module.css";
import { Prose } from "../../prose/prose";

const cx = classnames.bind(styles);

export const FlyingChocolateBanner = ({
  backgroundImageUrl,
  backgroundImageDescription,
  flyingChocolateBannerDescription,
  legalNotice,
}: {
  backgroundImageUrl: string | null;
  backgroundImageDescription: string | null;
  flyingChocolateBannerDescription: React.ReactNode;
  legalNotice: string | null;
}) => {
  const imageLoading = useImageLoading();

  return (
    <section className={cx("container")}>
      <div className={cx("inner")}>
        {backgroundImageUrl && (
          <img
            srcSet={responsiveImageHelper.createContentfulSrcSet(
              backgroundImageUrl,
            )}
            loading={imageLoading}
            sizes="100vw"
            className={cx("background")}
            alt={backgroundImageDescription ?? ""}
          />
        )}
        <div className={cx("inner-wrapper")}>
          <div className={cx("content-wrapper")}>
            <Prose className={cx("description")}>
              {flyingChocolateBannerDescription}
            </Prose>
            <p className={cx("legal-notice")}>{legalNotice}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
