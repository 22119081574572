"use client";

import classNames from "classnames/bind";
import { Stage } from "../../stage/stage";
import styles from "./contact-assistant-stage.module.css";
// import { DietaryMarks } from "../../dietary-marks/dietary-marks";
import { analyticsClick } from "../../../helpers/analytics";
import { isExternalLink } from "../../../helpers/isExternalLink";

const cx = classNames.bind(styles);

export const ContactAssistantStage = ({
  name,
  title,
  description,
  linkText,
  linkUrl,
  backgroundImageUrl,
}: {
  name: string | null;
  title: string | null;
  description: string | null;
  linkText: string | null;
  linkUrl: string | null;
  backgroundImageUrl: string | null;
}) => {
  const onClickHandler = () =>
    analyticsClick({
      value: linkText ?? "",
      clickUrl: linkUrl ?? "",
      elementPosition: "BlocksContactAssistantStage",
      method: isExternalLink(linkUrl ?? "")
        ? "Outbound: TRUE"
        : "Outbound: FALSE",
      contentType: "CTA",
    });

  return (
    <div className={cx("container")}>
      <Stage
        backgroundImageUrl={backgroundImageUrl}
        name={name}
        title={title}
        linkUrl={linkUrl}
        description={description}
        linkText={linkText}
        withRbButton={true}
        onClickLink={onClickHandler}
      />
      {/* High Sugar mark currently not implimented */}
      {/* <DietaryMarks
        kind="high-sugar"
        className={cx("dietary-mark-high-sugar")}
        sugarFree={false}
      /> */}
    </div>
  );
};
