"use client";

import classNames from "classnames/bind";
import {
  CosmosTitle,
  CosmosButton,
  CosmosIconFeedback,
} from "@cosmos/web/react";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import React from "react";
import { Kicker } from "../../kicker/kicker";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import styles from "./chat-assistant-teaser.module.css";
import { useKickerContext } from "../../../helpers/KickerContext";
import { analyticsClick } from "../../../helpers/analytics";
import { isExternalLink } from "../../../helpers/isExternalLink";
import { getImageSource } from "../../../helpers/getImageSource";

const cx = classNames.bind(styles);

export const ChatAssistantTeaser = ({
  botAnimationDescription = "",
  botAnimationUrl,
  bubbleText,
  ctaLink,
  ctaText,
  definitionListItems,
  definitionListTitle,
  heading,
  introduction,
  kicker,
}: {
  botAnimationDescription: string | null;
  botAnimationUrl: string | null;
  bubbleText: string | null;
  ctaLink: string | null;
  ctaText: string | null;
  definitionListItems: {
    title: string | null;
    value: string | null;
  }[];
  definitionListTitle: string | null;
  heading: string | null;
  introduction: React.ReactNode | string | null;
  kicker: string | null;
}) => {
  const kickerKind = useKickerContext();

  const onClickHandler = () =>
    analyticsClick({
      value: ctaText ?? "",
      clickUrl: ctaLink ?? "",
      elementPosition: "BlocksChatAssistantTeaser",
      method: isExternalLink(ctaLink ?? "")
        ? "Outbound: TRUE"
        : "Outbound: FALSE",
      contentType: "CTA",
    });

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <AnimationSection
          botAnimationUrl={botAnimationUrl}
          botAnimationDescription={botAnimationDescription}
          bubbleText={bubbleText}
          view="desktop"
        />
        <div className={cx("content")}>
          {(kicker || heading) && (
            <div className={cx("heading-group")}>
              {kicker && (
                <Kicker
                  className={cx("kicker")}
                  kind={kickerKind}
                  text={kicker}
                />
              )}
              {heading && <h3 className={cx("heading")}>{heading}</h3>}
            </div>
          )}
          {introduction && <div className={cx("text")}>{introduction}</div>}
          <AnimationSection
            botAnimationUrl={botAnimationUrl}
            botAnimationDescription={botAnimationDescription}
            bubbleText={bubbleText}
            view="mobile"
          />
          <div className={cx("button-wrapper")}>
            <CosmosButton
              href={ctaLink ?? undefined}
              kind="primary"
              size="large"
              iconPlacement="before"
              className={cx("button")}
              onClick={onClickHandler}
            >
              <CosmosIconFeedback slot="icon" className={cx("button-icon")} />
              {ctaText}
            </CosmosButton>
          </div>
          {(definitionListTitle ||
            (definitionListItems && definitionListItems.length > 0)) && (
            <div className={cx("contact-list")}>
              {definitionListTitle && (
                <CosmosTitle
                  appearance="dark"
                  size="normal"
                  tag="h4"
                  className={cx("table-title")}
                >
                  {definitionListTitle}
                </CosmosTitle>
              )}
              {definitionListItems && definitionListItems.length > 0 ? (
                <table className={cx("table")}>
                  <tbody>
                    {definitionListItems.map((row, index) => (
                      <tr key={index}>
                        <td>{row.title}</td>
                        <td>{row.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AnimationSection = ({
  botAnimationDescription,
  botAnimationUrl,
  bubbleText,
  view,
}: {
  botAnimationDescription: string | null;
  botAnimationUrl: string | null;
  bubbleText: string | null;
  view: "mobile" | "desktop";
}) => {
  const imageLoading = useImageLoading();
  const imageSource = botAnimationUrl ? getImageSource(botAnimationUrl) : null;

  return (
    <div className={cx("animation-container")} data-variant={view ?? undefined}>
      {botAnimationUrl &&
        (() => {
          const imageProps = {
            loading: imageLoading,
            className: cx("animation"),
            alt: botAnimationDescription ?? "",
            sizes: "180px",
            height: undefined,
            width: undefined,
          };

          switch (imageSource) {
            case "contentful": {
              return (
                <img
                  {...imageProps}
                  srcSet={responsiveImageHelper.createContentfulSrcSet(
                    botAnimationUrl,
                  )}
                />
              );
            }
            case "storyblok": {
              return (
                <img
                  {...imageProps}
                  srcSet={responsiveImageHelper.createStoryblokSrcSet(
                    botAnimationUrl,
                  )}
                />
              );
            }
            default: {
              return <img {...imageProps} src={botAnimationUrl} />;
            }
          }
        })()}
      <div className={cx("bubble-container")}>
        <p className={cx("bubble-text")}>{bubbleText}</p>
      </div>
    </div>
  );
};
