"use client";

import { useId, useState } from "react";
import classnames from "classnames/bind";
import { CosmosButton, CosmosIconChevronDown } from "@cosmos/web/react";
import styles from "./generic-disclosure-text.module.css";

const cx = classnames.bind(styles);

export const GenericDisclosureText = ({
  readLessLabel = "View less",
  readMoreLabel = "View more",
  initialIsActive = false,
  children,
}: {
  readLessLabel?: string;
  readMoreLabel?: string;
  initialIsActive?: boolean;
  children: React.ReactNode;
}) => {
  const [isActive, setIsActive] = useState<boolean>(initialIsActive);
  const id = useId();

  /* `data-expanded` is not directly used, but could be useful as a CSS hook if we have a custom `className` prop */
  return (
    <div className={cx("container")} data-expanded={isActive}>
      <CosmosButton
        className={cx("button")}
        aria-expanded={isActive}
        aria-controls={id}
        kind="link"
        icon-placement="after"
        onClick={() => setIsActive((current) => !current)}
      >
        <CosmosIconChevronDown slot="icon" className={cx("button-icon")} />
        {isActive ? readLessLabel : readMoreLabel}
      </CosmosButton>
      <div id={id} className={cx("content")} hidden={!isActive}>
        <div className={cx("content-inner")}>{children}</div>
      </div>
    </div>
  );
};
