"use client";

import classNames from "classnames/bind";
import { Kicker } from "../../kicker/kicker";
import { useObserveElementRef } from "../../../helpers/in-viewport/in-viewport";
import { GenericDisclosureText } from "../../generic-disclosure-text/generic-disclosure-text";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { CONTENTFUL_IMAGE_BASE_URL } from "../../../helpers/constants";
import styles from "./mission-statement.module.css";
import { useKickerContext } from "../../../helpers/KickerContext";

const cx = classNames.bind(styles);

export const MissionStatement = ({
  kicker,
  heading,
  statement,
  introduction,
  disclosureText,
  readMoreLabel,
  readLessLabel,
}: {
  kicker: string | null;
  heading: string | null;
  statement: string | null;
  introduction: React.ReactNode;
  disclosureText: React.ReactNode;
  readMoreLabel: string | null;
  readLessLabel: string | null;
}) => {
  const addSpan = (text: string) => {
    return [...text].map((letter, index) => (
      <span key={index} style={{ "--c-index": index }}>
        {letter}
      </span>
    ));
  };

  const { ref, hasIntersected } = useObserveElementRef<HTMLDivElement>({
    threshold: 0.5,
  });

  /**
   * Note: This is a hard coded URL, if the asset changes on the server it will
   * still serve the older asset, until this URL is updated.
   *
   * - Ideally we would query for this URL, but we don't have a helper yet and
   *   it's unlikely to change.
   * - Ideally static assets are hosted on S3, as they could be safely deleted
   *   in contentful.
   **/
  const backgroundImageUrl = `${CONTENTFUL_IMAGE_BASE_URL}/lcr8qbvxj7mh/4YJde8SVOm7WeHSOv1luTp/571ac5bf5c0adefc7974882c26236cdb/mission-statement-background.png`;
  const backgroundBullsImageUrl = `${CONTENTFUL_IMAGE_BASE_URL}/lcr8qbvxj7mh/3t0Ic1fSZHOZe2JKcegBhQ/bf7c2ad320abfe102b6051baedeb85f0/mission-statement-background-bulls.png`;

  const kickerKind = useKickerContext();

  return (
    <section className={cx("container")}>
      <div className={cx("inner")}>
        {(kicker || heading) && (
          <h2 className={cx("heading-wrap")}>
            {kicker && (
              <Kicker
                className={cx("kicker")}
                kind={kickerKind}
                text={kicker}
              />
            )}
            {heading && <span className={cx("heading")}>{heading}</span>}
          </h2>
        )}

        {statement && (
          <div
            ref={ref}
            className={cx("mission", {
              "is-playing": hasIntersected,
            })}
          >
            <img
              srcSet={responsiveImageHelper.createContentfulSrcSet(
                backgroundImageUrl,
              )}
              className={cx("mission-background")}
              alt=""
              aria-hidden="true"
            />
            <img
              src={backgroundBullsImageUrl}
              className={cx("mission-background-bulls")}
              alt=""
              aria-hidden="true"
            />
            <div className={cx("mission-text-wrap")}>
              <p className={cx("mission-text-animated")}>
                {addSpan(statement)}
              </p>
            </div>
          </div>
        )}

        {introduction && (
          <div className={cx("introduction")}>
            <div className={cx("text")}>{introduction}</div>

            {disclosureText && (
              <GenericDisclosureText
                readMoreLabel={readMoreLabel ?? undefined}
                readLessLabel={readLessLabel ?? undefined}
              >
                <div className={cx("text")}>{disclosureText}</div>
              </GenericDisclosureText>
            )}
          </div>
        )}
      </div>
    </section>
  );
};
