"use client";

import classNames from "classnames/bind";
import { responsiveImageHelper } from "../../helpers/responsiveImageHelper";
import { getContentfulVideoUrl } from "../../helpers/getContentfulVideoUrl";
import { useImageLoading } from "../../helpers/ImageLoadingContext";
import styles from "./generic-2col-text-media.module.css";

const cx = classNames.bind(styles);

export const Generic2colTextMedia = ({
  heading,
  text,
  media,
  direction = "default",
}: {
  heading?: string;
  text?: React.ReactNode | string;
  media?: {
    contentType: string | null;
    url: string | null;
    description: string | null;
  } | null;
  direction?: string;
}) => {
  const imageLoading = useImageLoading();

  return (
    <div
      className={cx("container", {
        "container--reverse": direction === "reversed",
      })}
    >
      <div className={cx("column")}>
        {heading && <h3 className={cx("heading")}>{heading}</h3>}
        {text && <div className={cx("text")}>{text}</div>}
      </div>
      <div className={cx("column")}>
        {media && (
          <>
            {media.contentType === "video/mp4" ? (
              <>
                {media.url && (
                  <video
                    className={cx("media")}
                    preload="auto"
                    autoPlay={true}
                    playsInline={true}
                    muted={true}
                    loop={true}
                    src={
                      media.url ? getContentfulVideoUrl(media.url) : undefined
                    }
                  />
                )}
              </>
            ) : (
              <>
                {media.url && (
                  <img
                    loading={imageLoading}
                    srcSet={responsiveImageHelper.createContentfulSrcSet(
                      media.url,
                    )}
                    sizes="(min-width: 1000px) min(672px, 100vw), min(600px, 100vw)"
                    className={cx("media")}
                    alt={media.description ?? ""}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
